<template>
  <div class="posts-list">
    <masonry-layout v-if="postList && postList.length > 0" id="masonry" cols="2" gap="10">
      <template v-for="post in postList" :key="'post_' + post.PostId">
        <Suspense v-if="postList && postList.length > 0">
          <PostCardComponent v-if="checkIfVisible(post)" :data="post" :hide-profile="mode === 'self' || mode === 'influencer'" :hide-profile-name="mode === 'pjf'" :show-post-type="mode === 'self'" />
        </Suspense>
      </template>
    </masonry-layout>

    <Suspense v-if="!isFetching && postList.length == 0">
      <NoDataFoundComponent v-if="!isFetching && postList.length == 0" :size="'sm'" type="full" :title="$t.getTranslation('LBL_POSTS_EMPTY')" />
    </Suspense>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, inject, defineAsyncComponent, computed } from "vue";
import { configs, extendedConfigs } from "@/utils/configs.js";
import "@appnest/masonry-layout";
import { get } from "@/utils/axios";
import { helpers } from "@/utils/helpers.js";
import { useStore } from "@/store";

// import PostCardComponent from '@/components/cards/PostCardComponent.vue'
// import NoDataFoundComponent from '@/components/NoDataFoundComponent.vue'

export default defineComponent({
  name: "PostListComponent",
  components: {
    PostCardComponent: defineAsyncComponent(() => import(/* webpackChunkName: "post-card" */ /* webpackMode: "lazy" */ "@/components/cards/PostCardComponent.vue")),
    NoDataFoundComponent: defineAsyncComponent(() => import(/* webpackChunkName: "no-data" */ /* webpackMode: "lazy" */ "@/components/NoDataFoundComponent.vue")),
  },
  props: {
    data: Object,
    mode: String,
    size: { type: Number, default: 6 },
    userkey: String,
    isElastic: { type: Boolean, default: false },
  },
  setup(props) {
    const store = useStore();
    const $t = inject("$translation");
    const languageCode = computed(() => store.getters["translation/getLanguage"]);

    let page = 1;
    let lastPage = 1;

    const searchValue = ref("");
    const postList = ref([]);
    const isFetching = ref(false);
    const afterSearch = ref("");

    const getPostList = async (mode) => {
      if (isFetching.value) return true;
      isFetching.value = true;
      let currentMode = props?.mode && !(props.mode == "") ? props.mode : null;
      let url = extendedConfigs?.isElastic && props?.isElastic ? `/post/elastic/list` : `/post/list`;
      let ret = await get(url, {
        mode: currentMode,
        page: page,
        size: props.size,
        UserKey: props.userkey,
        SearchField: "MULTIPLE",
        SearchType: "LIKE",
        SearchValue: searchValue.value,
        LanguageCode: $t.getLanguage(),
        afterSearch: page > 1 ? afterSearch.value : "",
      });

      if (ret && ret.data && Array.isArray(ret.data)) {
        for (let [counter, item] of ret.data.entries()) {
          if (extendedConfigs?.isElastic && props?.isElastic && item.PostTranslation) {
            let pickAttr = _.pick(item.PostTranslation[0], ["PostHighlight", "PostTitle", "PostDescription", "PostText"]);
            item = { ...item, ...pickAttr };
          }
          postList.value.push(item);
        }

        afterSearch.value = ret.afterSearch ? ret.afterSearch : "";

        setTimeout(() => {
          let $masonry = document.querySelector("#masonry");
          $masonry && $masonry.scheduleLayout() ? $masonry.scheduleLayout() : "";
        }, 1000);

        isFetching.value = false;
        lastPage = ret?.lastPage;
        page++;
      }
    };

    const reInitPostList = (mode) => {
      postList.value = [];
      page = 1;
      getPostList(mode);
    };

    const loadMore = () => {
      return lastPage >= page ? getPostList() : false;
    };

    const search = (data) => {
      searchValue.value = data;
      postList.value = [];
      page = 1;
      getPostList();
    };

    const changeType = (data) => {
      searchValue.value = data;
      postList.value = [];
      page = 1;
      getPostList(data);
    };

    const checkIfVisible = (postInfo) => {
      let isVisible = true;

      if (["pjf", "explore"].indexOf(props?.mode) > -1 && postInfo.PostType == "VIDEO" && helpers.isBlank(postInfo.PostVideo)) {
        isVisible = false;
      }

      return isVisible;
    };

    onMounted(() => {
      getPostList();
    });

    return { postList, getPostList, reInitPostList, loadMore, search, checkIfVisible, isFetching, changeType };
  },
});
</script>

<style scoped>
.waterfall-cont {
  display: flex;
  flex-direction: row;
}
.waterfall-child-cont {
  padding: 5px;
  width: 50%;
}
</style>
